



























import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import {
  BreadcrumbInterface,
} from './types';

@Component
export default class PageLayout extends Vue {
  @Prop({ type: Array, default: () => ([]) })
  private breadcrumbs!: BreadcrumbInterface[];

  get isMainPage(): boolean {
    return this.$route.name === 'Home';
  }

  get breadcrumbsItems(): BreadcrumbInterface[] {
    return [
      {
        title: 'Главная',
        to: '/',
      },
      ...this.breadcrumbs,
    ];
  }
}
