








































import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import type {
  HostelInterface,
} from '@/types';

@Component
export default class HostelPrice extends Vue {
  @Prop({ type: Object, required: true })
  public readonly hostel!: HostelInterface;

  @Prop({ type: Boolean, default: false })
  public readonly hideTitle!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly hideAlcoveInfo!: boolean;

  public getEntrancePriceString(price: number): string {
    if (price > 0) {
      return `<strong>${price} ₽</strong>`;
    }

    return '<strong>бесплатно</strong>';
  }

  public getPriceString(price: HostelInterface['price']['rooms'] | HostelInterface['price']['alcove']): string {
    if (price.min !== price.max) {
      return `от <strong>${price.min} ₽</strong> до <strong>${price.max} ₽</strong>`;
    }

    return `<strong>${price.min} ₽</strong>`;
  }
}
