import {
  Component,
  Vue,
} from 'vue-property-decorator';
import PageLayout from '@/layout/Page.vue';
import GridTemplate from '@/components/GridTemplate.vue';
import HostelCard from '@/components/HostelCard.vue';
import AddHostel from '@/components/AddHostel.vue';
import {
  BreadcrumbInterface,
} from '@/layout/types';
import hostels from '@/assets/hostels.json';
import services from '@/assets/services.json';
import type {
  HostelInterface,
  AdditionalServiceInterface,
} from '@/types';
import type {
  FiltersInterface,
} from './types';

function filterByTitle(item: HostelInterface, title: FiltersInterface['title']): boolean {
  return item.title.toLowerCase().includes(title.toLowerCase());
}

function filterByEntrancePrice(item: HostelInterface, min: FiltersInterface['price-entrance-min'], max: FiltersInterface['price-entrance-max']): boolean {
  return item.price.entrance.adult >= min || item.price.entrance.adult <= max;
}

function filterByRoomsPrice(item: HostelInterface, min: FiltersInterface['price-rooms-min'], max: FiltersInterface['price-rooms-max']): boolean {
  return item.price.rooms.min >= min && ((max === 0) || (item.price.rooms.max <= max));
}

function filterByServices(item: HostelInterface, services: FiltersInterface['services']): boolean {
  return services.length === 0 || item.services.every(service => services.includes(service));
}

@Component({
  components: {
    PageLayout,
    GridTemplate,
    HostelCard,
    AddHostel,
  },
  metaInfo() {
    return this.$route.meta?.metaInfo;
  },
})
export default class Catalog extends Vue {
  public filters: FiltersInterface = {
    title: '',
    'price-entrance-min': 0,
    'price-entrance-max': 0,
    'price-rooms-min': 0,
    'price-rooms-max': 0,
    services: [],
  };

  public get breadcrumbs(): BreadcrumbInterface[] {
    return [
      {
        title: this.$route.meta?.breadCrumb,
        active: true,
      },
    ];
  }

  public get services(): AdditionalServiceInterface[] {
    return services;
  }

  public get filteredHostels(): HostelInterface[] {
    return hostels
      .filter((item: HostelInterface) => filterByTitle(item, this.filters.title))
      .filter((item: HostelInterface) => filterByEntrancePrice(item, this.filters['price-entrance-min'], this.filters['price-entrance-max']))
      .filter((item: HostelInterface) => filterByRoomsPrice(item, this.filters['price-rooms-min'], this.filters['price-rooms-max']))
      .filter((item: HostelInterface) => filterByServices(item, this.filters.services));
  }

  public getServicesByIdArray(serviceIds: AdditionalServiceInterface['id'][]): AdditionalServiceInterface[] {
    return services.filter(service => serviceIds.includes(service.id));
  }
}
