


















































import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import objectFitImages from 'object-fit-images';
import HostelPrice from '@/components/HostelPrice.vue';
import HostelContacts from '@/components/HostelContacts.vue';
import type {
  HostelInterface,
} from '@/types';

@Component({
  components: {
    HostelPrice,
    HostelContacts,
  },
})
export default class HostelCard extends Vue {
  @Prop({ type: Object, required: true })
  public readonly hostel!: HostelInterface;

  public get mainImage(): string {
    return this.hostel.images[0];
  }

  private mounted(): void {
    objectFitImages('.card > img');
  }
}
