









import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class GridTemplate extends Vue {
  @Prop({ type: String, default: '320px' })
  private readonly min!: string;

  @Prop({ type: String, default: '1fr' })
  private readonly max!: string;

  public get style(): Record<string, string> {
    return {
      'grid-template-columns': `repeat(auto-fill, minmax(${this.min}, ${this.max}))`,
    };
  }
}
