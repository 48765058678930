

































import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import {
  formatPhone,
} from '@/utils';
import type {
  HostelInterface,
} from '@/types';

@Component
export default class HostelContacts extends Vue {
  @Prop({ type: Object, required: true })
  public readonly contacts!: HostelInterface['contacts'];

  @Prop({ type: Boolean, default: false })
  public readonly hideTitle!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly onlyPhones!: boolean;

  public get phones(): {raw: string, formatted: string}[] {
    return this.contacts.phones.map(phone => ({
      raw: phone,
      formatted: formatPhone(phone),
    }));
  }
}
