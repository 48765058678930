import { render, staticRenderFns } from "./HostelContacts.vue?vue&type=template&id=5611c141&scoped=true&"
import script from "./HostelContacts.vue?vue&type=script&lang=ts&"
export * from "./HostelContacts.vue?vue&type=script&lang=ts&"
import style0 from "./HostelContacts.vue?vue&type=style&index=0&id=5611c141&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5611c141",
  null
  
)

export default component.exports